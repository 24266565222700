"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAppData = exports.getLocales = void 0;
const API_ONE_APPLICATION = (_a = process.env.API_ONE_APPLICATION) !== null && _a !== void 0 ? _a : '';
const API_KEY = (_b = process.env.API_KEY) !== null && _b !== void 0 ? _b : '';
const APPLICATION_ID = (_c = process.env.APPLICATION_ID) !== null && _c !== void 0 ? _c : '';
const APPLICATION_VERSION = process.env.APPLICATION_VERSION;
const headers = new Headers();
headers.append('X-Header-AppId', APPLICATION_ID);
headers.append('X-Header-ApiKey', API_KEY);
if (APPLICATION_VERSION) {
    headers.append('X-Header-versionId', APPLICATION_VERSION);
}
if (location.origin.includes('.stag.') ||
    location.origin.includes('localhost') ||
    location.origin.includes('.hlx.')) {
    headers.append('aws-cf-cd-onecx-cache', 'disabled');
}
headers.append('Content-Type', 'application/json');
const getLocales = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield fetch(`${API_ONE_APPLICATION}/locales`, { headers, method: 'GET' }).then((response) => __awaiter(void 0, void 0, void 0, function* () {
        if (!response.ok) {
            return yield Promise.reject(new Error(`GetLocales request failed: ${response.statusText}`));
        }
        return yield response.json();
    }));
});
exports.getLocales = getLocales;
const getAppData = (localeId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield fetch(`${API_ONE_APPLICATION}/applications/locale/${localeId}`, {
        headers,
        method: 'GET'
    }).then((response) => __awaiter(void 0, void 0, void 0, function* () {
        if (!response.ok) {
            return yield Promise.reject(new Error(`GetAppData request failed: ${response.statusText}`));
        }
        return yield response.json();
    }));
});
exports.getAppData = getAppData;
