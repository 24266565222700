"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProductVersions = exports.getCachedJson = exports.getAllProducts = exports.getProduct = void 0;
const API_ONE_PRODUCT = (_a = process.env.API_ONE_PRODUCT) !== null && _a !== void 0 ? _a : '';
const API_KEY = (_c = (_b = process.env.ONE_PRODUCT_API_KEY) !== null && _b !== void 0 ? _b : process.env.API_KEY) !== null && _c !== void 0 ? _c : '';
const APPLICATION_ID = (_e = (_d = process.env.ONE_PRODUCT_APPLICATION_ID) !== null && _d !== void 0 ? _d : process.env.APPLICATION_ID) !== null && _e !== void 0 ? _e : '';
const headers = new Headers();
headers.append('X-Header-AppId', APPLICATION_ID);
headers.append('X-Header-ApiKey', API_KEY);
headers.append('Content-Type', 'application/json');
if (location.origin.includes('.stag.') ||
    location.origin.includes('localhost') ||
    location.origin.includes('.hlx.')) {
    headers.append('aws-cf-cd-onecx-cache', 'disabled');
}
const getProduct = (id, locale) => __awaiter(void 0, void 0, void 0, function* () {
    return yield fetch(`${API_ONE_PRODUCT}/${id}/data/${locale}`, {
        headers,
        method: 'GET'
    }).then((response) => __awaiter(void 0, void 0, void 0, function* () {
        if (!response.ok) {
            return yield Promise.reject(new Error(`GetProduct request failed: ${response.statusText}`));
        }
        return yield response.json();
    }));
});
exports.getProduct = getProduct;
const getAllProducts = (locale) => __awaiter(void 0, void 0, void 0, function* () {
    return yield fetch(`${API_ONE_PRODUCT}/data/${locale}`, {
        headers,
        method: 'GET'
    }).then((response) => __awaiter(void 0, void 0, void 0, function* () {
        if (!response.ok) {
            return yield Promise.reject(new Error(`GetAllProducts request failed: ${response.statusText}`));
        }
        return yield response.json();
    }));
});
exports.getAllProducts = getAllProducts;
const getProductVersions = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield fetch(`${API_ONE_PRODUCT}/${id}/versions}`, {
        headers,
        method: 'GET'
    }).then((response) => {
        if (!response.ok) {
            return Promise.reject(new Error(`GetProductVersions request failed: ${response.statusText}`));
        }
        return response.json();
    });
});
exports.getProductVersions = getProductVersions;
const getCachedJson = (url) => __awaiter(void 0, void 0, void 0, function* () {
    return yield fetch(url, { headers, method: 'GET' }).then((response) => __awaiter(void 0, void 0, void 0, function* () {
        if (!response.ok) {
            return yield Promise.reject(new Error('Failed to fetch cached JSON!'));
        }
        return yield response.json();
    }));
});
exports.getCachedJson = getCachedJson;
