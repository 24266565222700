"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const getDefaultPaths = () => ({
    total: 5,
    offset: 0,
    limit: 5,
    data: [
        {
            model: 'anthem',
            'pdp path': '/trucks/anthem/',
            'configurator path': '/#/anthem'
        },
        {
            model: 'granite',
            'pdp path': '/trucks/granite-series/',
            'configurator path': '/#/granite'
        },
        {
            model: 'pinnacle',
            'pdp path': '/trucks/pinnacle/',
            'configurator path': '/#/pinnacle'
        },
        {
            model: 'lr',
            'pdp path': '/trucks/anthem/',
            'configurator path': '/#/lr'
        },
        {
            model: 'md',
            'pdp path': '/trucks/md-series/',
            'configurator path': '/#/md'
        }
    ],
    ':type': 'sheet'
});
const usePaths = () => {
    const [paths, setPaths] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        const meta = document.querySelector('meta[name="truck-configurator"]');
        if (meta) {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            fetch(meta.content, { method: 'GET', headers })
                .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                else {
                    return Promise.reject(new Error('Failed to fetch trucks data: ' + res.statusText));
                }
            })
                .then((json) => {
                setPaths(json);
            })
                .catch((error) => {
                console.error(error);
            });
        }
        else {
            setPaths(getDefaultPaths());
        }
    }, [setPaths]);
    return paths;
};
exports.default = usePaths;
