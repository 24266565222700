"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const zustand_1 = require("zustand");
const types_1 = require("~clients/OneRodManager/types");
const useViewportState = (0, zustand_1.create)((set, get) => ({
    showFullscreen: false,
    setShowFullscreen: (value) => set({ showFullscreen: value }),
    show360Toggle: true,
    setShow360Toggle: (value) => set({ show360Toggle: value }),
    showTurntable: false,
    setShowTurntable: (value) => set({ showTurntable: value }),
    showEnvironmentToggle: true,
    setShowEnvironmentToggle: (value) => set({ showEnvironmentToggle: value }),
    setIsLoading: (isLoading) => set({ isImageLoading: isLoading }),
    isImageLoading: false,
    loadingOverlayVisible: false,
    showUI: true,
    loadingOverlayText: 'Applying template design to your Mack...',
    loadingOverlayProgress: 0,
    setLoadingOverlayProgress: (progress) => set({ loadingOverlayProgress: progress }),
    setShowUI: (show) => set({ showUI: show }),
    setShowPagination: (show) => set({ showPagination: show }),
    showPagination: true,
    showStudio: false,
    setShowStudio: (value) => set({ showStudio: value }),
    pagination: '',
    setPagination: (value) => set({ pagination: value }),
    imageStatus: types_1.ImageStatus.None,
    setImageStatus: (status) => set({ imageStatus: status }),
    showHotspots: false,
    setShowHotspots: (value) => set({ showHotspots: value }),
    showHotspotsToggle: true,
    setShowHotspotsToggle: (value) => set({ showHotspotsToggle: value }),
    scrollTargetIndex: -1,
    setScrollTargetIndex: (value) => set({ scrollTargetIndex: value }),
    slideshowPage: 0,
    setSlideshowPage: (value) => set({ slideshowPage: value })
}));
exports.default = useViewportState;
