"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const framer_motion_1 = require("framer-motion");
const clamp = (value, min, max) => Math.max(min, Math.min(max, value));
const smoothStep = (x) => {
    return -2 * Math.pow(x, 3) + 3 * Math.pow(x, 2);
};
class TurntableHandler {
    constructor(images, time = 0, duration = 1, snapPoints) {
        this.duration = 1;
        this.requestFrame = 0;
        this.position = 0;
        this.lastFrame = -1;
        this.target = 0;
        this.lastVel = 0;
        this.startPos = 0;
        this.startTime = 0;
        this.images = [];
        this.canvas = null;
        this.snapPoints = [];
        this.isDragging = false;
        this.eventTarget = document.createDocumentFragment();
        this.setImages(images);
        this.position = time;
        this.duration = duration;
        this.target = time;
        if (snapPoints) {
            this.snapPoints = snapPoints;
        }
    }
    dispatchEvent(event) {
        this.eventTarget.dispatchEvent(event);
    }
    addEventListener(type, callback) {
        this.eventTarget.addEventListener(type, callback);
    }
    removeEventListener(type, callback) {
        this.eventTarget.removeEventListener(type, callback);
    }
    setTime(value, immediate) {
        if (immediate) {
            this.position = value;
            this.target = value;
            this.dispatchEvent(new CustomEvent('oncursor', { detail: value }));
        }
        else {
            this.startTime = Date.now();
            this.startPos = this.position;
            this.target = value;
            this.startAnimation();
            this.dispatchEvent(new CustomEvent('oncursor', { detail: this.position }));
        }
    }
    setCanvas(canvas) {
        this.canvas = canvas;
        this.render();
    }
    updateFrame() {
        // We want to drag from right to left - that means going backwards through the image sequence though in this case
        // Unsure if this will be the general case - it is possible that we will just render out the sequences to be clockwise
        var _a, _b;
        const newFrame = Math.round(this.position * (this.images.length - 1));
        if (newFrame !== this.lastFrame) {
            if ((_a = this.images[newFrame]) === null || _a === void 0 ? void 0 : _a.complete) {
                this.drawImage((_b = this.images) === null || _b === void 0 ? void 0 : _b[newFrame]);
                this.lastFrame = newFrame;
            }
            else {
                //onLoadStart?.();
                this.dispatchEvent(new Event('onloadstart'));
            }
        }
    }
    animate() {
        let delta = this.target - this.startPos; // Difference between target and current value;
        const invDelta = this.target < this.startPos
            ? 1 - this.startPos + this.target
            : -this.startPos - (1 - this.target); // Distance required to go around the seam
        if (Math.abs(invDelta) < Math.abs(delta)) {
            // It's faster to loop around the seam
            delta = invDelta;
        }
        const dist = Math.abs(this.target - this.position);
        const t = clamp((Date.now() - this.startTime) / (this.duration * 1000), 0, 1);
        const targetPos = this.startPos + delta * smoothStep(t);
        this.position = (0, framer_motion_1.wrap)(0, 1, targetPos);
        this.dispatchEvent(new CustomEvent('oncursor', { detail: this.position }));
        this.updateFrame();
        if (dist <= 0.0005) {
            this.requestFrame = 0;
            return; // Animation finished
        }
        this.requestFrame = requestAnimationFrame(() => this.animate());
    }
    stopAnimation() {
        if (this.requestFrame) {
            cancelAnimationFrame(this.requestFrame);
        }
    }
    startAnimation() {
        this.stopAnimation();
        this.requestFrame = requestAnimationFrame(() => this.animate());
    }
    drawImage(image) {
        var _a;
        const context = (_a = this.canvas) === null || _a === void 0 ? void 0 : _a.getContext('2d');
        if (this.canvas && context && image) {
            // This logic calculates an image fit similar to "cover"
            const ratio = image.width / image.height;
            const canvasWidth = this.canvas.width;
            const canvasHeight = this.canvas.height;
            let newWidth = canvasWidth;
            let newHeight = newWidth / ratio;
            if (newHeight < canvasHeight) {
                newHeight = canvasHeight;
                newWidth = newHeight * ratio;
            }
            const xOffset = newWidth > canvasWidth ? (canvasWidth - newWidth) / 2 : 0;
            const yOffset = newHeight > canvasHeight ? (canvasHeight - newHeight) / 2 : 0;
            context.clearRect(0, 0, canvasWidth, canvasHeight);
            context.drawImage(image, xOffset, yOffset, newWidth, newHeight);
        }
    }
    render() {
        const currentFrame = Math.round(this.position * (this.images.length - 1));
        const image = this.images[currentFrame];
        if (image === null || image === void 0 ? void 0 : image.complete) {
            this.drawImage(image);
        }
    }
    setImages(images) {
        if (images.length === this.images.length && !images.some((v, i) => this.images[i].src !== v)) {
            // Images are the same
            return;
        }
        this.images.length = images.length;
        images.forEach((img, i) => {
            var _a;
            if (((_a = this.images[i]) === null || _a === void 0 ? void 0 : _a.src) === img)
                return;
            const image = (this.images[i] = new Image());
            const onLoad = () => {
                if (!this.images[i])
                    return;
                this.dispatchEvent(new CustomEvent('onprogress', { detail: i / Math.max(images.length, 1) }));
                const currentFrame = Math.round(this.position * (images.length - 1));
                if (i === currentFrame) {
                    this.drawImage(image);
                    this.dispatchEvent(new Event('onloaded'));
                }
            };
            image.onload = onLoad;
            image.src = img;
            if (image.complete) {
                onLoad();
            }
        });
    }
    onPointerUp() {
        this.isDragging = false;
        if (this.snapPoints.length) {
            // Handle snapping
            const nextPos = this.position + this.lastVel * 2;
            const closest = this.snapPoints.reduce((prev, curr) => Math.abs(curr - nextPos) < Math.abs(prev - nextPos) ? curr : prev);
            this.dispatchEvent(new CustomEvent('onsnap', {
                detail: this.snapPoints.findIndex((x) => x === closest)
            }));
            this.setTime(closest);
        }
        this.lastVel = 0;
    }
    onPointerDown() {
        this.isDragging = true;
    }
    onPointerMove(evt) {
        if (this.isDragging && this.canvas) {
            const dx = evt.movementX;
            const width = this.canvas.getBoundingClientRect().width;
            const velocity = -dx / width;
            this.lastVel = velocity;
            this.position = (0, framer_motion_1.wrap)(0, 1, this.position + velocity);
            this.updateFrame();
        }
    }
}
exports.default = TurntableHandler;
