"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.submitEmail = exports.submitLead = void 0;
const API_SALESFORCE_FORM = (_a = process.env.API_ONE_APPLICATION) !== null && _a !== void 0 ? _a : '';
const API_KEY = (_b = process.env.API_KEY) !== null && _b !== void 0 ? _b : '';
const APPLICATION_ID = (_c = process.env.APPLICATION_ID) !== null && _c !== void 0 ? _c : '';
const submitEmail = (fields, configUrl, imageUrl, productInterest, emailConsent) => __awaiter(void 0, void 0, void 0, function* () {
    const searchParams = new URLSearchParams(Object.assign(Object.assign({}, fields), { configurationUrl: configUrl !== null && configUrl !== void 0 ? configUrl : '', imageUrl: imageUrl !== null && imageUrl !== void 0 ? imageUrl : '', emailConsent: emailConsent !== null && emailConsent !== void 0 ? emailConsent : '' }));
    const headers = new Headers();
    headers.append('X-Header-AppId', APPLICATION_ID);
    headers.append('X-Header-ApiKey', API_KEY);
    const product = productInterest === null || productInterest === void 0 ? void 0 : productInterest.replace(' ', '-').toLowerCase();
    return yield fetch(`${API_SALESFORCE_FORM}/api-dispatcher/email-${product}?${searchParams.toString()}`, {
        method: 'POST',
        headers
    }).then((response) => __awaiter(void 0, void 0, void 0, function* () {
        if (!response.ok) {
            return yield Promise.reject(new Error(`EmailLead post failed: ${response.statusText}`));
        }
        return yield response.json();
    }));
});
exports.submitEmail = submitEmail;
const submitLead = (fields, market, configUrl, imageUrl, productInterest, emailConsent) => __awaiter(void 0, void 0, void 0, function* () {
    const searchParams = new URLSearchParams(Object.assign(Object.assign({}, fields), { configurationUrl: configUrl !== null && configUrl !== void 0 ? configUrl : '', imageUrl: imageUrl !== null && imageUrl !== void 0 ? imageUrl : '', emailConsent: emailConsent !== null && emailConsent !== void 0 ? emailConsent : '' }));
    const headers = new Headers();
    headers.append('X-Header-AppId', APPLICATION_ID);
    headers.append('X-Header-ApiKey', API_KEY);
    const product = productInterest === null || productInterest === void 0 ? void 0 : productInterest.replace(' ', '-').toLowerCase();
    const externalMarket = market && market !== 'en';
    return yield fetch(`${API_SALESFORCE_FORM}/api-dispatcher/leads-${product}${externalMarket ? `-${market}` : ''}?${searchParams.toString()}`, {
        method: 'POST',
        headers
    }).then((response) => __awaiter(void 0, void 0, void 0, function* () {
        if (!response.ok) {
            return yield Promise.reject(new Error(`SubmitLead post failed: ${response.statusText}`));
        }
        return yield response.json();
    }));
});
exports.submitLead = submitLead;
