"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        label: 'United States',
        value: 'United States'
    },
    {
        label: 'Canada',
        value: 'Canada'
    },
    {
        label: 'Mexico',
        value: 'Mexico'
    },
    {
        label: 'Argentina',
        value: 'Argentina'
    },
    {
        label: 'Trinidad and Tobago',
        value: 'Trinidad and Tobago'
    },
    {
        label: 'Venezuela',
        value: 'Venezuela'
    },
    {
        label: 'Colombia',
        value: 'Colombia'
    },
    {
        label: 'Bolivia',
        value: 'Bolivia'
    },
    {
        label: 'Ecuador',
        value: 'Ecuador'
    },
    {
        label: 'Costa Rica',
        value: 'Costa Rica'
    },
    {
        label: 'Guyana',
        value: 'Guyana'
    },
    {
        label: 'Nicaragua',
        value: 'Nicaragua'
    },
    {
        label: 'Panama',
        value: 'Panama'
    },
    {
        label: 'Peru',
        value: 'Peru'
    },
    {
        label: 'El Salvador',
        value: 'El Salvador'
    },
    {
        label: 'Chile',
        value: 'Chile'
    },
    {
        label: 'Dominican Republic',
        value: 'Dominican Republic'
    },
    {
        label: 'Bahamas',
        value: 'Bahamas'
    },
    {
        label: 'Guatemala',
        value: 'Guatemala'
    },
    {
        label: 'Haiti',
        value: 'Haiti'
    },
    {
        label: 'Honduras',
        value: 'Honduras'
    }
].sort((a, b) => {
    if (a.value < b.value)
        return -1;
    if (a.value > b.value)
        return 1;
    return 0;
});
