"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateZip = exports.validateEmail = exports.validateName = exports.validatePhone = void 0;
const validatePhone = (value) => {
    return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(value);
};
exports.validatePhone = validatePhone;
const validateName = (value) => {
    return /^[\w'\-,.][^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/im.test(value);
};
exports.validateName = validateName;
const validateEmail = (value) => {
    if (!value.includes('@') || !value.includes('.')) {
        return false;
    }
    return true;
};
exports.validateEmail = validateEmail;
const validateZip = (value) => {
    //return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    return /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/.test(value);
};
exports.validateZip = validateZip;
