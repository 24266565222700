"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadAsset = exports.checkStatus = exports.getRender = void 0;
const API_ONE_RODMANAGER = process.env.API_ONE_RODMANAGER;
const API_KEY = (_b = (_a = process.env.ONE_PRODUCT_API_KEY) !== null && _a !== void 0 ? _a : process.env.API_KEY) !== null && _b !== void 0 ? _b : '';
const APPLICATION_ID = (_d = (_c = process.env.ONE_PRODUCT_APPLICATION_ID) !== null && _c !== void 0 ? _c : process.env.APPLICATION_ID) !== null && _d !== void 0 ? _d : '';
const headers = new Headers();
headers.append('X-Header-AppId', APPLICATION_ID);
headers.append('X-Header-ApiKey', API_KEY);
headers.append('Content-Type', 'application/json');
const getRender = (request) => __awaiter(void 0, void 0, void 0, function* () {
    return yield fetch(`${API_ONE_RODMANAGER}/image-provider/get-render`, {
        headers,
        method: 'POST',
        body: JSON.stringify(request)
    }).then((response) => __awaiter(void 0, void 0, void 0, function* () {
        if (!response.ok) {
            return yield Promise.reject(new Error(`GetRender request failed: ${response.statusText}`));
        }
        return yield response.json();
    }));
});
exports.getRender = getRender;
const checkStatus = (request) => __awaiter(void 0, void 0, void 0, function* () {
    return yield fetch(`${API_ONE_RODMANAGER}/image-provider/check-status`, {
        headers,
        method: 'POST',
        body: JSON.stringify(request)
    }).then((response) => __awaiter(void 0, void 0, void 0, function* () {
        if (!response.ok) {
            return yield Promise.reject(new Error(`GetStatus request failed: ${response.statusText}`));
        }
        return yield response.json();
    }));
});
exports.checkStatus = checkStatus;
const uploadAsset = (request) => __awaiter(void 0, void 0, void 0, function* () {
    const data = new FormData();
    data.append('image', request.image);
    const customHeaders = new Headers();
    customHeaders.append('X-Header-AppId', APPLICATION_ID);
    customHeaders.append('X-Header-ApiKey', API_KEY);
    const response = yield fetch(`${API_ONE_RODMANAGER}/upload-asset`, {
        headers: customHeaders,
        method: 'POST',
        body: data
    });
    if (!response.ok) {
        return { data: { error: `UploadAsset request failed: ${response.statusText}`, isError: true } };
    }
    return yield response.json();
});
exports.uploadAsset = uploadAsset;
