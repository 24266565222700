"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const processProduct = (product) => {
    var _a;
    const findDependencyById = (id) => {
        var _a;
        for (const group of (_a = product.component_groups) !== null && _a !== void 0 ? _a : []) {
            for (const comp of group.components) {
                const match = comp.available_variations.find((v) => v.id === id);
                if (match) {
                    return { group: group.name, component: comp.key, variation: match.original_key };
                }
            }
        }
    };
    (_a = product.component_groups) === null || _a === void 0 ? void 0 : _a.forEach((group) => {
        group.components.forEach((component) => {
            component.attribute_map = {};
            component.attribute_values.forEach((attr) => {
                component.attribute_map[attr.attribute_name] = attr.value;
            });
            component.available_variations.forEach((variation) => {
                var _a;
                variation.attribute_map = {};
                variation.attribute_values.forEach((attr) => {
                    variation.attribute_map[attr.attribute_name] = attr.value;
                });
                (_a = variation.dependencies) === null || _a === void 0 ? void 0 : _a.forEach((dependency) => {
                    const data = findDependencyById(dependency.depends_on_variation_id);
                    if (data) {
                        dependency.group = data.group;
                        dependency.component = data.component;
                        dependency.variation = data.variation;
                    }
                });
                variation.assets.forEach((asset) => {
                    asset.attribute_map = {};
                    asset.attribute_values.forEach((attr) => {
                        asset.attribute_map[attr.attribute_name] = attr.value;
                    });
                });
            });
        });
    });
    return product;
};
exports.default = processProduct;
