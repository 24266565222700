"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const zustand_1 = require("zustand");
const componentsToDefault = [
    'exterior.exhaust',
    'exterior.air_horns',
    'interior.rear_exit',
    'chassis.wheelbase'
];
const getEmissionLevels = () => {
    const tag = document.querySelector('meta[name="emission-levels"]');
    if (tag) {
        return new Set(tag.content.split(','));
    }
    return new Set();
};
const useConfiguration = (0, zustand_1.create)((set, get) => ({
    models: [],
    currentModel: 0,
    setModels: (value) => set({ models: value.filter((m) => m.category_id === 3) }),
    getCurrentModel: () => get().models[get().currentModel],
    setCurrentModel: (model) => {
        const index = get().models.findIndex((x) => x.name === model);
        if (index !== -1) {
            set({ currentModel: index });
        }
        get().setDefaultOptions(get().models[index]);
    },
    customColor: '#ffffff',
    setCustomColor: (value) => set({ customColor: value }),
    branding: { name: '', assets: {} },
    setBrandingSlot: (slot, assetName) => {
        const newBranding = Object.assign({}, get().branding);
        newBranding.assets[slot] = assetName;
        set({ branding: newBranding });
    },
    setBrandingSlots: (slots) => {
        const newBranding = Object.assign({}, get().branding);
        slots.forEach(({ slot, assetName }) => {
            newBranding.assets[slot] = assetName;
        });
        set({ branding: newBranding });
    },
    setBrandingName: (name) => {
        const newBranding = Object.assign({}, get().branding);
        newBranding.name = name;
        set({ branding: newBranding });
    },
    isDealer: false,
    setIsDealer: (value) => set({ isDealer: value }),
    selectedFeatures: {},
    setLaunchOptions: (selectedFeatures) => {
        componentsToDefault.forEach((item) => {
            const [group, component] = item.split('.');
            const defaultOption = get().getDefaultOption(group, component, selectedFeatures);
            if (defaultOption) {
                if (!selectedFeatures[group]) {
                    selectedFeatures[group] = {};
                }
                selectedFeatures[group][component] = defaultOption.key;
            }
            else {
                console.warn(`No default option found for component: ${component} in group: ${group}`);
            }
        });
    },
    setSelectedFeature: (group, feature, value) => {
        const selectedFeatures = Object.assign({}, get().selectedFeatures);
        if (!selectedFeatures[group]) {
            selectedFeatures[group] = {};
        }
        selectedFeatures[group][feature] = value;
        if (feature === 'cabs') {
            get().setLaunchOptions(selectedFeatures);
        }
        // This selection might impact other choices through dependencies
        get().applyDependencies(selectedFeatures);
        set({ selectedFeatures });
    },
    setSelectedFeatures: (features) => {
        set({ selectedFeatures: features });
    },
    getFeature: (group, feature) => {
        var _a;
        const currentModel = get().getCurrentModel();
        const componentGroup = (_a = currentModel === null || currentModel === void 0 ? void 0 : currentModel.component_groups) === null || _a === void 0 ? void 0 : _a.find((x) => x.name === group);
        const component = componentGroup === null || componentGroup === void 0 ? void 0 : componentGroup.components.find((x) => x.key === feature);
        return component;
    },
    getDefaultOption: (group, feature, selectedFeatures) => {
        var _a;
        const component = get().getFeature(group, feature);
        const selected = selectedFeatures !== null && selectedFeatures !== void 0 ? selectedFeatures : get().selectedFeatures;
        if (component) {
            const variations = get()
                .filterOptions(component, selected)
                .filter((x) => !x.original_key.startsWith('tab_'));
            return (_a = variations === null || variations === void 0 ? void 0 : variations.find((x) => x.is_default)) !== null && _a !== void 0 ? _a : variations[0];
        }
        return undefined;
    },
    getGroup: (group) => {
        const currentModel = get().getCurrentModel();
        if (currentModel === null || currentModel === void 0 ? void 0 : currentModel.component_groups) {
            return currentModel.component_groups.find((x) => x.name === group);
        }
        return undefined;
    },
    filterOptions: (component, selectedFeatures) => {
        var _a, _b;
        const selection = selectedFeatures !== null && selectedFeatures !== void 0 ? selectedFeatures : get().selectedFeatures;
        const emissionLevels = get().emissionLevels;
        const hasAero = [
            'side_roof_fairings',
            'side_roof_chassis_fairings',
            'side_mid_roof_fairings'
        ].includes((_a = selection.aerodynamics) === null || _a === void 0 ? void 0 : _a.aero_package);
        const variations = (_b = component === null || component === void 0 ? void 0 : component.available_variations) !== null && _b !== void 0 ? _b : [];
        return variations.filter((variation) => {
            var _a;
            const dependencies = variation.dependencies;
            // These dependencies are too complex for OneCX, so we need to hardcode them
            const highRoofMarkers = ['rectangular_flush', 'rectangular_semi_flush'].includes(variation.original_key);
            const isSleeper = ((_a = selection.body) === null || _a === void 0 ? void 0 : _a.cabs) === '70_sleeper';
            if ((component === null || component === void 0 ? void 0 : component.key) === 'marker_lights' && highRoofMarkers !== hasAero && !isSleeper) {
                return false;
            }
            const noVisor = hasAero && !isSleeper;
            if ((component === null || component === void 0 ? void 0 : component.key) === 'sun_visor' && variation.original_key === 'visor_body' && noVisor) {
                return false;
            }
            if ((component === null || component === void 0 ? void 0 : component.key) === 'emission_level' && !emissionLevels.has(variation.original_key)) {
                return false;
            }
            return !(dependencies === null || dependencies === void 0 ? void 0 : dependencies.find((dep) => { var _a; return ((_a = selection[dep.group]) === null || _a === void 0 ? void 0 : _a[dep.component]) === dep.variation && dep.variation !== undefined; }));
        });
    },
    applyDependencies: (selection, truckfinder = false) => {
        var _a, _b, _c;
        const filterOptions = get().filterOptions;
        const data = truckfinder ? get().truckfinderData : get().getCurrentModel();
        for (const group of (_a = data === null || data === void 0 ? void 0 : data.component_groups) !== null && _a !== void 0 ? _a : []) {
            for (const component of group.components) {
                if (component.key === 'branding')
                    continue;
                if (component.key === 'hotspots')
                    continue;
                const variations = filterOptions(component, selection);
                const selected = (_b = selection[group.name]) === null || _b === void 0 ? void 0 : _b[component.key];
                if (!variations.find((v) => v.original_key === selected) && !(selected === null || selected === void 0 ? void 0 : selected.startsWith('#'))) {
                    const defaultOption = (_c = get().getDefaultOption(group.name, component.key, selection)) === null || _c === void 0 ? void 0 : _c.original_key;
                    if (defaultOption) {
                        if (!selection[group.name]) {
                            selection[group.name] = {};
                        }
                        console.log('Changing value for ' + component.key, selection[group.name][component.key], ' to ', defaultOption);
                        selection[group.name][component.key] = defaultOption;
                    }
                    else if (selected) {
                        console.log('Deleting selection for ' + component.key);
                        delete selection[group.name][component.key];
                    }
                }
            }
        }
    },
    setDefaultOptions: (product, overrides) => {
        var _a;
        const currentModel = product !== null && product !== void 0 ? product : get().getCurrentModel();
        const filterOptions = get().filterOptions;
        let newSelection = {};
        (_a = currentModel === null || currentModel === void 0 ? void 0 : currentModel.component_groups) === null || _a === void 0 ? void 0 : _a.forEach((group) => {
            const noTabs = (v) => v.attribute_map['Type'] !== 'Filter';
            group.components.forEach((component) => {
                var _a;
                if (['branding', 'hotspots'].includes(component.key))
                    return;
                const variations = filterOptions(component, newSelection).filter(noTabs);
                const defaultVariant = (_a = variations.find((v) => v.is_default)) !== null && _a !== void 0 ? _a : variations[0];
                if (!newSelection[group.name]) {
                    newSelection[group.name] = {};
                }
                if (defaultVariant === null || defaultVariant === void 0 ? void 0 : defaultVariant.original_key) {
                    newSelection[group.name][component.key] = defaultVariant.original_key;
                }
            });
        });
        if (overrides) {
            newSelection = Object.assign(Object.assign({}, newSelection), overrides);
        }
        set({ selectedFeatures: newSelection });
    },
    getConfigurationString: (fname, lname, product, options) => {
        var _a;
        const config = [];
        const selectedFeatures = options !== null && options !== void 0 ? options : get().selectedFeatures;
        const branding = get().branding;
        const currentModel = product !== null && product !== void 0 ? product : get().getCurrentModel();
        if (!currentModel) {
            return null;
        }
        for (const groupName of Object.keys(selectedFeatures)) {
            const group = (_a = currentModel === null || currentModel === void 0 ? void 0 : currentModel.component_groups) === null || _a === void 0 ? void 0 : _a.find((g) => g.name === groupName);
            for (const componentName of Object.keys(selectedFeatures[groupName])) {
                const component = group === null || group === void 0 ? void 0 : group.components.find((c) => c.key === componentName);
                const variationName = selectedFeatures[groupName][componentName];
                const variation = component === null || component === void 0 ? void 0 : component.available_variations.find((v) => v.original_key === variationName);
                if (component && variation) {
                    config.push(component === null || component === void 0 ? void 0 : component.id, variation.id);
                }
                else if (component && variationName.startsWith('#')) {
                    config.push(component.id, variationName);
                }
            }
        }
        const response = {
            c: [currentModel === null || currentModel === void 0 ? void 0 : currentModel.id, ...config]
        };
        if (Object.keys(branding.assets).length) {
            response.bn = branding.name;
            response.a = branding.assets;
        }
        if (fname && lname) {
            response.f = fname;
            response.l = lname;
        }
        return btoa(JSON.stringify(response));
    },
    parseConfigurationString: (config) => {
        var _a;
        const obj = JSON.parse(atob(config));
        const result = {
            model: '',
            selectedFeatures: {},
            branding: obj.bn && obj.a
                ? {
                    name: obj.bn,
                    assets: obj.a
                }
                : undefined,
            fname: obj.f,
            lname: obj.l
        };
        const models = get().models;
        if (!models) {
            return null;
        }
        const model = models.find((m) => m.id === obj.c[0]);
        if (!model) {
            return null;
        }
        result.model = model.name;
        for (let i = 1; i < obj.c.length; i += 2) {
            const key = obj.c[i];
            const value = obj.c[i + 1];
            if (key !== undefined && value !== undefined) {
                const componentId = key;
                const variationId = value;
                const group = (_a = model === null || model === void 0 ? void 0 : model.component_groups) === null || _a === void 0 ? void 0 : _a.find((g) => g.components.find((c) => c.id === componentId));
                const component = group === null || group === void 0 ? void 0 : group.components.find((c) => c.id === componentId);
                if (group && component) {
                    if (!result.selectedFeatures[group.name]) {
                        result.selectedFeatures[group.name] = {};
                    }
                    if (typeof value === 'string') {
                        result.selectedFeatures[group.name][component.key] = value;
                    }
                    else {
                        const variation = component === null || component === void 0 ? void 0 : component.available_variations.find((v) => v.id === variationId);
                        if (variation) {
                            result.selectedFeatures[group.name][component.key] = variation.original_key;
                        }
                    }
                }
            }
        }
        return result;
    },
    truckfinderData: null,
    setTruckfinderData: (product) => set({ truckfinderData: product }),
    emissionLevels: getEmissionLevels()
}));
exports.default = useConfiguration;
