"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const zustand_1 = require("zustand");
const enums_1 = require("~utils/enums");
const useSection = (0, zustand_1.create)((set, get) => ({
    sections: [],
    currentSection: 0,
    currentStep: 0,
    showSteps: false,
    modalSection: '',
    getCurrentSection: () => {
        return get().sections[get().currentSection];
    },
    setCurrentSection: (index) => {
        set({ currentSection: index });
    },
    getCurrentStep: () => {
        var _a;
        return ((_a = get().sections[get().currentSection]) === null || _a === void 0 ? void 0 : _a.steps[get().currentStep]) || '';
    },
    setCurrentStep: (index) => {
        set({ currentStep: index });
    },
    setShowSteps: (value) => {
        set({ showSteps: value });
    },
    getProgress: () => {
        const sections = get().sections;
        const totalSteps = Math.max(0, sections.reduce((acc, x) => acc + x.steps.length, 0) - 1);
        const currentSection = get().currentSection;
        if (!totalSteps)
            return 0;
        let completedSteps = get().currentStep;
        for (let i = 0; i < currentSection; i++) {
            completedSteps += sections[i].steps.length;
        }
        return completedSteps / totalSteps;
    },
    nextStep: () => {
        var _a, _b;
        const sections = get().sections;
        const currentSection = get().currentSection;
        const currentStep = get().currentStep;
        if (currentStep < ((_a = sections[currentSection]) === null || _a === void 0 ? void 0 : _a.steps.length) - 1) {
            set({ currentStep: currentStep + 1 });
        }
        else if (currentSection < sections.length - 1) {
            set({
                currentSection: currentSection + 1,
                currentStep: 0,
                showSteps: ((_b = sections[currentSection + 1]) === null || _b === void 0 ? void 0 : _b.steps.length) > 1
            });
        }
    },
    prevStep: () => {
        var _a, _b;
        const sections = get().sections;
        const currentSection = get().currentSection;
        const currentStep = get().currentStep;
        if (currentStep > 0) {
            set({ currentStep: currentStep - 1 });
        }
        else if (currentSection > 0) {
            set({
                currentSection: currentSection - 1,
                currentStep: ((_a = sections[currentSection - 1]) === null || _a === void 0 ? void 0 : _a.steps.length) - 1,
                showSteps: ((_b = sections[currentSection - 1]) === null || _b === void 0 ? void 0 : _b.steps.length) > 1
            });
        }
    },
    nextSection: () => {
        const sections = get().sections;
        const currentSection = get().currentSection;
        if (currentSection < sections.length - 1) {
            set({ currentSection: currentSection + 1, currentStep: 0 });
        }
    },
    isFirstStep: () => {
        return get().currentSection === 0 && get().currentStep === 0;
    },
    setSectionsFromProduct: (product, appData, selection) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const sections = [];
        const interiorHotspots = (_b = (_a = product.component_groups) === null || _a === void 0 ? void 0 : _a.find((g) => g.name === enums_1.SectionId.Interior)) === null || _b === void 0 ? void 0 : _b.components.find((c) => c.key === 'hotspots');
        const exteriorHotspots = (_d = (_c = product.component_groups) === null || _c === void 0 ? void 0 : _c.find((g) => g.name === enums_1.SectionId.Exterior)) === null || _d === void 0 ? void 0 : _d.components.find((c) => c.key === 'hotspots');
        for (const group of (_e = product.component_groups) !== null && _e !== void 0 ? _e : []) {
            const steps = [];
            if (group.name === 'hidden') {
                continue;
            }
            const groupSection = appData[`${product.name}.${group.name}`];
            if (groupSection) {
                let n = 0;
                while (true) {
                    const name = groupSection.key_values[`step_${n}_title`];
                    const id = groupSection.key_values[`step_${n}_id`];
                    const components = group.components.filter((c) => {
                        const stepAttr = c.attribute_map[enums_1.AttributeType.Step];
                        return stepAttr && stepAttr === `${n}` && c.available_variations.length;
                    });
                    if (id && name) {
                        if (components.some((c) => { var _a; return !!((_a = selection[group.name]) === null || _a === void 0 ? void 0 : _a[c.key]); }) && components.length) {
                            steps.push({
                                name,
                                id,
                                components
                            });
                        }
                    }
                    else {
                        break;
                    }
                    n++;
                }
            }
            const hotspots = group.name === enums_1.SectionId.Interior ? interiorHotspots : exteriorHotspots;
            sections.push({
                name: (_f = appData[`${product.name}.${group.name}`]) === null || _f === void 0 ? void 0 : _f.section_name_localized,
                id: group.name,
                steps,
                hotspots
            });
        }
        sections.push({
            name: (_g = appData['summary_sections']) === null || _g === void 0 ? void 0 : _g.section_name_localized,
            id: 'summary',
            steps: [
                {
                    name: (_h = appData['summary_sections']) === null || _h === void 0 ? void 0 : _h.section_name_localized,
                    id: 'summary',
                    components: []
                }
            ]
        });
        set({ sections });
    },
    setModalSection: (modalSection) => {
        set({ modalSection });
    }
}));
exports.default = useSection;
